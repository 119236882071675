import React from 'react';
import './Home.css';
import {useTypewriter} from "react-simple-typewriter";
import Skills from './Skills';

const Home = () => {
  
  const {text} = useTypewriter({
    words: [' MERN Stack Developer</>', ' Front-End Developer</>', ' Back-End Developer</>'], loop:10 })
  
  return(
    <>
    <section className="hero" id="home">
    <div className="container f_flex top">
    <div className="left top">
    <h3>WELCOME TO MY WORLD</h3>
    <h1>
    Hi, I'm <span>Prabhjot</span>
    </h1>
    <h3 className="anim">
          a
          <span>{text}</span>
      </h3>

      <p>I have really deep interest in MERN Stack development i have done several projects through MERN Stack and looking forward to gain more experience.</p>

    
      </div>
    <div className='right'>
    <div className='right_img'>
        {/* <img src="https://iili.io/VL0vjV.png" alt='' /> */}
        <img src='https://iili.io/XbRMf2.png' alt='' />
    </div>
    
    </div>
    </div>
    
    <div className="heading " id='skills'>
    <h1>My Skills</h1>
    </div>
    <div className="wrapper">

    <div className='aboutwrap'>
      <h1>Hi I’M Prabhjot Nice To Meet You</h1>
      <p>I started my web development journey 1 years ago from learning basic languages to learning Reactjs, Nodejs, Mongodb.</p>
    </div>
  
    </div>
    
    <Skills />
    </section>
    </>
    )
}

export default Home;
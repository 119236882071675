const Portfolio_data = [
  {
    id: 1,
    category: "NODEJS, EXPRESSJS, MONGODB",
    totalLike: "600",
    title: "Dynamic Blog Website ",
    image: "https://iili.io/VDHMCP.jpg",
    url: "https://cutt.ly/UOF2yaM",
    detail: "This Website Allow Us to Read    Blogs And User Can Create Their Own Blog By Just Going To /admin On Url, I Created This Website With The Help Of Backend- Languages Like Node And Express And For DataBase I Used MongoDB. In This Project I Used Ejs To Reuse My Components And to Render Data To Templates From DataBase. • Through This Project I Was Abled To Store Data In Database Using MongoDB And Mongoose Which Is A Package Of Node. • I Used Body-Parser To Get The Input That User Filled In And Routing To Respond To User Request Through URL"
  },
  {
    id: 2,
    category: "PASSPORT, EXPRESSJS, MONGODB",
    totalLike: "750",
    title: "Authentication and Security",
    image: "https://iili.io/VDJH8b.jpg",
    url: "https://cutt.ly/DOF2fXz",
    detail: "This Is A Website Where User Can Share Their Secrets Anonymously But User Can Only Submit Their Secrets If He/She LogIn. • If User Want To Submit Their Secrets Then First Of All He/She Have To Register When User Got Register User Can Then Login Whenever User Want. • User Registration Information Will Be Stored In MongoDB With The Help Of Passport And PassportLocalMogoose. • User Password Will Be Stored With Hashing And Salting Which Is Near TO Impossible TO Crack. • User Can Also Login Through Google Account For This I Used OAuth Using Passport Login Using Google Account Makes User Password Extremely Secure. • Through Express-Session And Passport I Was Abled To Use Cookies Which Helps User In That Way If User Register Or Login Their Self Then Cookie Will Going TO Be Stored In Their Browser. • After Cookies Will Store In User Browser Then Next Time User Don’t Need To Login Cookies Will Gonna Authenticate User Through User Id That Whether This Is A Valid User Or Not."
  },
  {
    id: 3,
    category: "EXPRESSJS, MONGODB",
    totalLike: "630",
    title: "Todo List App ",
    image: "https://iili.io/VDJMN4.md.jpg",
    url: "https://cutt.ly/kI72gke",
    detail: "As Per Its Name It Is A ToDo List Web App Will Allows User To Make Their Tasks List User Can Create Different ToDo Lists By Just Going TO / And Whatever Name You Want To Give To your ToDo And The Amazing Thing Is That User Can Toggle To New And Previous To By Just going TO / and Name Of Their ToDo. • User ToDo List Data Will Going To Be Stored In MongoDB Which Mean No Matter How Much ToDo User Create They Will Always Going To Get Their Previous ToDo Data Safe And Stored And User Can Delete Lists Whenever User Want. • I Used Body-Pares To Get User Input EJS For Templates Which Makes It Easy To Transfer Data From One Component To Another And Also Allows Us To Use JavaScript In Between Html."
  },
  {
    id: 4,
    category: "REACTJS",
    totalLike: "360",
    title: "Gym Landing Page",
    image: "https://iili.io/WqczmJ.jpg",
    url: "https://gym-landing-page-35efbe.netlify.app/",
    detail: "A Beautiful Landing Page Using Reactjs I Also Used UseStates In It And Media Queries To Make It Responsive And I Used Flex Box For Layout Purpose."
  },

]
export default Portfolio_data
import './Home.css';
const Skills = (props) => {

    return (
    <div className='pos'>
    <div className='skull'>
    <div className='skillContainer' id='skillContainer1'>
        <div className='aboutContain'>
            <img src='https://iili.io/XbK9rx.png' alt='skill'/>
            <h1>Front-end Skills</h1>
            <p>I like to code things from scratch, and enjoy bringing ideas to life in the browser.</p>
            <h1>Laguages</h1>
            <ul>
                <li>Html</li>
                <li>Css</li>
                <li>JavaScript</li>
                <li>Reactjs</li>
                <li>Redux</li>
                <li>Bootstrap</li>
                <li>Figma</li>
            </ul>
        </div>

    </div>
    <div className='skillContainer' id='skillContainer2'>
    <div className='aboutContain'>
            <img src='https://iili.io/XbfYAB.png' alt='skill'/>
            <h1>Back-end Skills</h1>
            <p>I like to do server side things in backend as much as much i like to do frontend.</p>
            <h1>Laguages</h1>
            <ul>
                <li>Nodejs</li>
                <li>Expressjs</li>
                <li>MongoDB</li>
            </ul>
        </div>
    </div>
    <div className='skillContainer' id='skillContainer3'>
    <div className='aboutContain'>
            <img src='https://iili.io/XbfWSR.png' alt='skill'/>
            <h1>Other Skills</h1>
            <p>I like to learn new things here are some technologies i learnt apart from Mern.</p>
            <h1>Laguages</h1>
            <ul>
                <li>Nextjs</li>
                <li>ReactNative</li>
                <li>Wordpress</li>
                <li>Git</li>
            </ul>
        </div>
    </div>
    </div>
    </div>    
    )
}

export default Skills;
import React,{useState} from 'react';
import './header.css';

const Header = () => {
  
  window.addEventListener("scroll", function(){
      
      const header = document.querySelector('.header')
      header.classList.toggle("active", window.scrollY > 100 )
      
    })
    
    const [Mobile, setMobile] = useState(false)
  
  
  return (
    
    
    
    <>
    <header>
    <div className="container d_flex">
    <div className="logo">
    <h2>PRABH</h2>
    </div>
    <div className="navlink">
    <ul className={Mobile ? "nav-links-mobile" : "link f_flex uppercase"} onClick={() => setMobile(false)}>
    <li><a href="/" >Home</a></li>
    <li><a href="#skills" >Skills</a></li>
    <li><a href="#portfolio" >Projects</a></li>
    <li><a href="https://drive.google.com/file/d/1suTCUw4cIzktFPCLNkKnoB06urLkBoUV/view?usp=drivesdk" rel="noreferrer" target="_blank">Resume</a></li>
    <li><a href="#contact" >Contact</a></li>
    <li><button className="home-btn"><a href="#contact" >HIRE ME</a></button></li>
    </ul>
    <button className="toggle" onClick={() => setMobile(!Mobile)
    }>
    {Mobile  ? <i className="fas fa-times close home-btn"></i> : <i className="fas fa-bars open"></i>
    }
    
    </button>
    
    </div>
    
    </div>
    </header>
    </>
    )
}

export default Header;
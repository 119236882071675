import Header from './components/head/Header';
import Home from './components/hero/Home';
import Portfolio from './components/portfolio/Portfolio';
import Contact from './components/contact/Contact';
import './App.css';

function App() {
  return (
    <>
      <Header />
      <Home />
      <Portfolio />
      <Contact />
    </>
  );
}

export default App;
